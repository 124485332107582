import Page, { getInitialProps } from "@xem/router/page";
export const config = {
    unstable_runtimeJS: true
};
export default function Callback(props) {
    return Page(props);
}
Callback.getInitialProps = (props)=>{
    return getInitialProps({
        ...props,
        load: true
    });
};
